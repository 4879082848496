var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Wrapper', [_c('header', [_vm._v(_vm._s(_vm.$tc('enginesManager.model.create')))]), _c('InputStyled', {
    attrs: {
      "required": !_vm.$v.newModel.required
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.newModel.$model,
      expression: "$v.newModel.$model",
      modifiers: {
        "trim": true
      }
    }],
    domProps: {
      "value": _vm.$v.newModel.$model
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.newModel, "$model", $event.target.value.trim());
      },
      "blur": function blur($event) {
        return _vm.$forceUpdate();
      }
    }
  })]), _vm.$v.newModel.$dirty && !_vm.$v.newModel.required ? _c('p', {
    staticClass: "errors"
  }, [_vm._v(_vm._s(_vm.$tc('messages.required')))]) : _vm._e(), _c('ButtonsGroup', [_c('ButtonStyled', {
    attrs: {
      "disabled": _vm.$v.newModel.$model === '' || _vm.$v.newModel.$dirty && !_vm.$v.newModel.required
    },
    on: {
      "click": _vm.createEngineModel
    }
  }, [_vm._v(" " + _vm._s(_vm.$tc('actions.create')) + " ")]), _c('ButtonStyled', {
    attrs: {
      "color": "grey"
    },
    on: {
      "click": function click($event) {
        return _vm.$root.$emit('closeOverlay');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$tc('actions.cancel')) + " ")])], 1), _vm.pageErrors ? _c('p', [_c('span', {
    staticClass: "error"
  }, [_vm._v(_vm._s(_vm.$tc('error')) + ": ")]), _vm._v(" " + _vm._s(_vm.pageErrors) + " ")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }