<template>
  <Wrapper>
    <header>{{ $tc('enginesManager.model.create') }}</header>
    <InputStyled :required="!$v.newModel.required">
      <input v-model.trim="$v.newModel.$model" />
    </InputStyled>
    <p class="errors" v-if="$v.newModel.$dirty && !$v.newModel.required">{{ $tc('messages.required') }}</p>
    <ButtonsGroup>
      <ButtonStyled :disabled="$v.newModel.$model === '' || ($v.newModel.$dirty && !$v.newModel.required)" @click="createEngineModel">
        {{ $tc('actions.create') }}
      </ButtonStyled>
      <ButtonStyled color="grey" @click="$root.$emit('closeOverlay')">
        {{ $tc('actions.cancel') }}
      </ButtonStyled>
    </ButtonsGroup>
    <p v-if="pageErrors">
      <span class="error">{{ $tc('error') }}: </span> {{ pageErrors }}
    </p>
  </Wrapper>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { FlashMessages } from '@common/singletons'
import { Wrapper, InputStyled, ButtonsGroup, ButtonStyled } from './styles'
import ASSET_ENGINES_CREATE_MODEL_QUERY from '#/graphql/assetEngines/CreateEngineModel.gql'

export default {
  mixins: [validationMixin],
  props: {
    args: {
      type: Object,
    },
  },
  components: {
    Wrapper,
    InputStyled,
    ButtonsGroup,
    ButtonStyled,
  },
  validations: {
    newModel: {
      required,
    },
  },
  data() {
    return {
      newModel: '',
      pageErrors: '',
    }
  },
  methods: {
    createEngineModel() {
      this.$apollo
        .mutate({
          mutation: ASSET_ENGINES_CREATE_MODEL_QUERY,
          variables: {
            data: {
              name: this.newModel,
            },
          },
        })
        .then(() => {
          FlashMessages.$emit('success', this.$t('messages.entityCreatedSuccessfully', { entity: this.$t('enginesManager.model.name') }), {
            timeout: 3000,
          })
          this.$root.$emit('closeOverlay')
        })
        .catch(error => {
          this.pageErrors = error?.graphQLErrors[0]?.message
        })
    },
  },
}
</script>
